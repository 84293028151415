

























































import {Component, Vue} from 'vue-property-decorator';
import {createTag, Tag} from "@/types";
import UButton from '@/ui-lib/button/Button.vue';
import USelectField, {SelectValue, SelectOptions} from "@/ui-lib/select-field/SelectField.vue";
import {OrderDir, TagOrderColumn, TagService} from "@/api";

export type TagDeleteModalParams = {
  tag: Tag;
  onDeleted?: () => void;
}

@Component({
  components: {
    USelectField,
    UButton,
  },
})
export default class extends Vue {
  private tag: Tag = createTag();
  private onDeleted: () => void = (() => {
    return;
  });
  private isLoading = false;
  private replaceAbort: AbortController | null = null;
  private replaceValue: SelectValue = null;
  private replaceOptions: SelectOptions = [];

  private close(): void {
    this.$modal.hide('tag-delete');
  }

  private get btnDisabled() {
    if (this.isLoading) {
      return true
    }

    return this.tag.trackCount === 0 ? false : !this.replaceValue
  }

  private beforeOpen({params}: { params: TagDeleteModalParams }) {
    if (this.replaceAbort) {
      this.replaceAbort.abort();
    }

    this.tag = params?.tag || createTag();
    this.onDeleted = params?.onDeleted || (() => {
      return;
    });
    this.isLoading = false;
    this.replaceValue = null;
    this.replaceOptions = [];
  }

  private async onSearch(val: string, loading: (l: boolean) => void) {
    if (this.replaceAbort) {
      this.replaceAbort.abort();
    }

    if (!val) {
      this.replaceOptions = [];
      return;
    }

    loading(true);
    await this.load(val);
    loading(false);
  }

  private async load(search: string) {
    try {
      this.replaceAbort = new AbortController();

      const res = await TagService.list({
        start: 0,
        limit: 20,
        search: search,
        order_column: TagOrderColumn.id,
        order_dir: OrderDir.desc,
        exclude_ids: [this.tag.id],
      }, {signal: this.replaceAbort.signal});

      this.replaceOptions = res.list.map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // ok
      } else {
        this.$notify({type: 'error', title: 'Ошибка', text: e?.message || 'Неизвестная ошибка'});
      }
    }
  }

  private async submit() {
    this.isLoading = true;

    try {
      let targetID: number | undefined = undefined;

      if (this.replaceValue) {
        if (typeof this.replaceValue.value === 'number') {
          targetID = this.replaceValue.value;
        } else {
          targetID = parseInt(this.replaceValue.value);
        }
      }

      await TagService.delete(this.tag.id, targetID);
      this.$notify({type: 'success', title: 'Тег удален'});
      this.onDeleted();
      this.close();
    } catch (e) {
      this.$notify({type: 'error', title: 'Ошибка', text: e?.message || 'Неизвестная ошибка'});
    }

    this.isLoading = false;
  }
}
