export interface Tag {
  id: number;
  name: string;
  trackCount: number;
  search_aliases?: string[] | null;
}

export const createTag = (): Tag => ({
  id: 0,
  name: '',
  trackCount: 0,
})

export const fillTag = (src: Tag, dst: Tag) => {
  dst.id = src.id
  dst.name = src.name
  dst.trackCount = src.trackCount

  dst.search_aliases?.splice(0)
  if (src.search_aliases) {
    dst.search_aliases?.push(...src.search_aliases)
  }
}

export interface TagForm {
  id?: number;
  search_aliases: string[];
  name: string;
}

export const createTagForm = (tg?: Tag): TagForm => ({
  id: tg ? tg.id : undefined,
  search_aliases: tg?.search_aliases || [],
  name: tg ? tg.name : ''
});
